@import '~antd/dist/antd.css';

:root {
    // Colors
    --color-black: #333;
    --color-white: #fff;
    --color-gray-0: #e5e5e5;
    --color-gray-1: #F0F0F0;
    --color-gray-2: #D9D9D9;
    --color-gray-3: #2E2E33;
    --color-gray-4: #F3F4F6;
    --color-brand: #FFD880;

    //Radius
    --border-radius-small: 2px; 
    --border-radius-medium: 4px; 

    // Shadows
    --box-shadow-drawer: 0px 3px 6px -4px rgba(0, 0, 0, 0.12), 0px 6px 16px rgba(0, 0, 0, 0.08), 0px 9px 28px 8px rgba(0, 0, 0, 0.05);
    --box-shadow-wrapper: 0px 0.333333px 16px rgba(0, 0, 0, 0.01), 0px 1.25px 5px rgba(0, 0, 0, 0.06);

    // Pixel to rem values
    --14px: 0.875rem;
    --15px: 0.9375rem;
    --16px: 1rem;
    --17px: 1.0625rem;
    --18px: 1.125rem;
    --19px: 1.1875rem;
    --20px: 1.25rem;
    --21px: 1.3125rem;
}

.fullHeight {
  height: 100%;
}

.padding-1x {
  padding: 16px;
}

.space {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

